<app-loader *ngIf="loader"></app-loader>

<div
  style="
    height: calc(100vh + 30px);
    background-image: url('https://juanpaycdn.azureedge.net/juanpayassets/juanpay-gray.jpg');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  "
>
  <div class="bg-overlay bg-overlay-white"></div>
  <!-- <div class="floatExternalApps">
    <div class="floatContainer">
      <div class="floatHeader">
        <span class="fa fa-close"></span>
      </div>

    
    </div>
  </div>
  -->
  <div class="padding pb-0 pt-4 bg-dark">
    <div class="row">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="card v-100">
          <div class="card-body">
            <div class="row row-sm">
              <div class="col-sm-12 col-md-6 col-lg-1 d-flex justify-content-center">
                <!-- <img src="../../../assets/images/icon/img_avatar.png" alt="avatar" class="avatar" /> -->
                <a data-toggle="dropdown" class="nav-link d-flex align-items-center px-2 text-color">
                  <!-- <span class="avatar w-24" style="margin: -2px"><img src="../assets/img/a1.jpg" alt="..." /></span> -->
                  <!-- <span class="avatar w-30" style="margin: -2px">
                    <img class="img-side" *ngIf="photo" [src]="photo | authImage | async" alt="VIP" />
                    <img class="img-side" *ngIf="!photo" src="https://i.ibb.co/cczMjzp/male.jpg" alt="VIP" />
                  </span> -->
                  <span class="avatar w-64">
                    <img
                      class="w-48 avatar"
                      *ngIf="photo"
                      [src]="photo | authImage | async"
                      alt="VIP"
                      onerror="this.src='https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png';"
                    />
                    <img
                      class="w-48 avatar"
                      *ngIf="!photo"
                      src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                      alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                    />
                    <i class="on"></i>
                  </span>
                </a>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-2">
                <small class="text-muted">Cashier</small>
                <div class="mt-2 font-weight-500">
                  <span class="text-highlight mt-2 font-weight-500 text-primary">{{ cashierName }} </span>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-2">
                <small class="text-muted">V-Wallet Balance</small>
                <div class="text-highlight mt-2 font-weight-500">{{ wallet | number: "1.2-2" }}</div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-5">
                <small class="text-muted">Date Today</small>
                <div class="text-highlight mt-2 font-weight-500">{{ currentDate }}</div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-2">
                <small class="text-muted">CSR</small>
                <div class="text-highlight mt-2 font-weight-500">{{ csrCode }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-10 col-lg-2">
        <!-- <div class="card v-100">
          <div class="card-body" style="padding-bottom: 0px !important">
            <h6>GoVIPCenter Bonanza</h6>
            <p>Most Transacted Multisys</p>
            <button
              class="btn btn-sm btn-danger rounded-pill mb-3 w-100"
              (click)="openPreviewProductEventLeaderboard(previewProductEventLeaderboard)"
            >
              View Leaderboard
            </button>
          </div>
        </div> -->
      </div>

      <div class="col-sm-12 col-md-2 col-lg-2">
        <div class="card v-100">
          <div class="card-body d-flex justify-content-center align-items-center" style="height: 88px">
            <!-- <div
              class="btn btn-sm btn-success mini-buttons mr-1 ml-1"
              data-toggle="tooltip"
              data-placement="left"
              title="Recent Transaction"
              *ngIf="isOldTransaction"
              (click)="getActiveTransaction()"
            >
              <i class="fa fa-history"></i>
            </div> -->
            <div
              class="btn btn-sm btn-dark mini-buttons mr-1 ml-1"
              data-toggle="tooltip"
              data-placement="left"
              title="View Announcement"
              (click)="displayAnnouncement(announcementModal)"
            >
              <i class="fa fa-bullhorn"></i>
              <!-- <i data-feather="bell"></i> -->
              <span class="badge badge-pill badge-up bg-danger">!</span>
            </div>

            <div
              class="btn btn-sm btn-primary mini-buttons mr-1 ml-1"
              data-toggle="tooltip"
              data-placement="left"
              title="Fullscreen"
              (click)="openFullscreen()"
            >
              <i class="fa fa-window-maximize"></i>
            </div>

            <div
              class="btn btn-sm btn-info mini-buttons mr-1 ml-1"
              (click)="backToDashboard()"
              data-toggle="tooltip"
              data-placement="Back to dashboard"
              title="Back to home page"
            >
              <i class="fa fa-arrow-left"> </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <div
    class="padding pt-3"
    style="
      background-image: url(../../../assets/images/banner/Design\ for\ Website\ Background\ 1.jpg);
      background-size: cover;
    "
  >
    <div class="page-content">
      <div class="row">
        <!-- Partners -->
        <div class="col-sm-12 col-md-12 col-lg-3">
          <div class="">
            <div class="d-flex">
              <ul class="nav nav-pills w-100">
                <li class="nav-item mr-2">
                  <a class="nav-link active" href="#" data-toggle="tab" data-target="#tab_1">
                    <span class="text-highlight font-weight-bold" style="font-size: 10px">
                      &nbsp;&nbsp;&nbsp; Global &nbsp;&nbsp;&nbsp;
                    </span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" data-toggle="tab" data-target="#tab_2">
                    <span class="text-highlight font-weight-bold" style="font-size: 10px">
                      &nbsp;&nbsp;&nbsp; Favorites &nbsp; <i class="fa fa-heart text-danger"></i> &nbsp;&nbsp;
                    </span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 col-lg-12">
                <div class="tab-content">
                  <div class="tab-pane fade show active" id="tab_1">
                    <div class="card p-2" style="height: 73vh; overflow-y: scroll; overflow-x: hidden">
                      <div class="panel-group" id="accordion">
                        <div *ngIf="cashierUser?.bpn" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse1">
                                Bills Payment Network</a
                              >
                            </h6>
                          </div>
                          <div id="collapse1" class="panel-collapse collapse show">
                            <div class="panel-body">
                              <div *ngIf="activeBillsApplications.length == 0">
                                <h2>No product available.</h2>
                              </div>
                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'Meralco')"
                                  *ngIf="partnerswitch[0]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file0501503b-cb8f-4705-8ece-c694860d19c9.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: cover"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >Meralco
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'BAYAD_CENTER')"
                                  *ngIf="partnerswitch[4]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file72ada932-2876-4f0a-b2b7-3a919cabefbf.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: cover"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >Bayad
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeBillsApplications"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="cashierUser?.aln" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse2">
                                Airtime Loading Network</a
                              >
                            </h6>
                          </div>
                          <div id="collapse2" class="panel-collapse collapse">
                            <div class="panel-body">
                              <div *ngIf="activeAirtimeApplications.length == 0">
                                <h2>No product available.</h2>
                              </div>

                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeAirtimeApplications"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="cashierUser?.rn" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse3">
                                Remittances Network</a
                              >
                            </h6>
                          </div>
                          <div id="collapse3" class="panel-collapse collapse">
                            <div class="panel-body">
                              <div *ngIf="activeAirtimeApplications.length == 0">
                                <h2>No product available.</h2>
                              </div>

                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeRemitanceApplications"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'SPX Rider Remit')"
                                  *ngIf="partnerswitch[3]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file09355088-c489-48e6-b6ce-ab08b4788de4.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                        >
                                          SPX Rider Remit
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="cashierUser?.in" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse5">
                                Insurance Network</a
                              >
                            </h6>
                          </div>
                          <div id="collapse5" class="panel-collapse collapse">
                            <div class="panel-body">
                              <div *ngIf="activeInsurances.length == 0">
                                <h2>No product available.</h2>
                              </div>

                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeInsurances"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="cashierUser?.gs" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse6">
                                JuanPay Services</a
                              >
                            </h6>
                          </div>
                          <div id="collapse6" class="panel-collapse collapse show">
                            <div class="panel-body">
                              <div *ngIf="activeGoVIPCenterServices.length == 0">
                                <h2>No product available.</h2>
                              </div>

                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeGoVIPCenterServices"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'GOVIPX_TOPUP')"
                                  *ngIf="partnerswitch[1]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filecc1b1c35-c2ca-40eb-bd54-b7ac951c98f5.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                        >
                                          Vips Topup
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'GOVIPX_CASHOUT')"
                                  *ngIf="partnerswitch[2]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileadfeea13-f2a1-4eeb-8f65-6e8b62f1b479.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                        >
                                          Vips Cashout
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  (click)="openCreateTransaction(createTransactionModal, 'PAYGRAM')"
                                  *ngIf="partnerswitch[5]?.value"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file59e06210-2d00-4191-83c5-b95b9f41cd2a.png"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                        >
                                          Paygram
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="cashierUser?.on" class="panel panel-default">
                          <div class="panel-heading">
                            <h6 class="panel-title col-title p-2 bg-dark" style="text-align: center">
                              <a class="text-white" data-toggle="collapse" data-parent="#accordion" href="#collapse4">
                                Other Network</a
                              >
                            </h6>
                          </div>
                          <div id="collapse4" class="panel-collapse collapse">
                            <div class="panel-body">
                              <div *ngIf="activeOtherApplications.length == 0">
                                <h2>No product available.</h2>
                              </div>

                              <div class="row">
                                <div
                                  class="col-sm-12 col-md-6 col-lg-6 pointer"
                                  *ngFor="let application of activeOtherApplications"
                                  (click)="openCreateTransaction(createTransactionModal, application)"
                                >
                                  <div
                                    class="card"
                                    data-sr-id="12"
                                    style="
                                      visibility: visible;
                                      transform: none;
                                      opacity: 1;
                                      transition: none 0s ease 0s;
                                    "
                                  >
                                    <div class="card-body pl-0 pr-0">
                                      <div class="pos-rlt" style="height: 78px">
                                        <div
                                          class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center"
                                        >
                                          <img
                                            [src]="getImageFromMasterList(application.parameter)"
                                            height="70"
                                            width="70"
                                            class="img-responsive"
                                            style="object-fit: contain"
                                          />
                                        </div>
                                      </div>
                                      <hr class="mb-0" />
                                      <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                        <span
                                          class="text-highlight mt-2 font-weight-bold text-sm"
                                          style="font-size: 12px"
                                          >{{ application.value.thumbnail.title }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- POS FAVORITES -->
                  <div class="tab-pane fade" id="tab_2">
                    <div class="card" style="height: 73vh; overflow-y: scroll; overflow-x: hidden">
                      <div class="panel-body">
                        <div
                          class="input-group mb-3 p-3 bg-light"
                          style="border-style: outset; position: -webkit-sticky; position: sticky; top: 0; z-index: 99"
                          [formGroup]="favoriteFormGroup"
                        >
                          <!-- <div class="input-group-prepend">
                            <button class="btn btn-primary btn-sm mr-3"><i class="fa fa-cogs"></i></button>
                          </div> -->
                          <input
                            type="text"
                            class="form-control"
                            formControlName="search"
                            appDebounceClick
                            [debounceTime]="500"
                            placeholder="Search..."
                            aria-label=""
                            aria-describedby="basic-addon1"
                          />
                          <div class="input-group-append">
                            <button class="btn btn-secondary btn-sm">Search &nbsp;<i class="fa fa-search"></i></button>
                          </div>
                        </div>

                        <div class="pl-5 pr-5" *ngIf="shortcutAccumulator.length == 0">
                          <h3>
                            No favorites as of the moment. <br /><br />If you wish to add on your list, kindly go to
                            Point of Sales, Choose your favorite biller and Click the Add button beside the "Next"
                            button.
                          </h3>
                        </div>

                        <div class="row">
                          <div
                            class="col-sm-12 col-md-6 col-lg-6 pointer"
                            *ngFor="let apps of shortcutAppList"
                            (click)="openCreateTransactionShortcut(createTransactionModal, apps)"
                          >
                            <div
                              class="card"
                              data-sr-id="12"
                              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
                            >
                              <div class="card-body pl-0 pr-0">
                                <div class="pos-rlt" style="height: 78px">
                                  <div class="pos-abt w-100 h-100 d-flex align-items-center justify-content-center">
                                    <img
                                      [src]="apps.logo"
                                      height="70"
                                      width="70"
                                      class="img-responsive"
                                      style="object-fit: contain"
                                    />
                                  </div>
                                </div>
                                <hr class="mb-0" />
                                <div class="pt-1 pl-0 pr-0 d-flex justify-content-center">
                                  <span
                                    class="text-highlight mt-2 pl-5 pr-5 font-weight-bold text-sm"
                                    style="font-size: 12px; overflow: hidden; text-overflow: ellipsis"
                                    >{{ apps.name }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end of Partners -->

        <!-- transaction items -->

        <div class="col-sm-12 col-md-6 col-lg-7">
          <div class="row bg-dark p-1" style="border-radius: 5px">
            <div class="col-sm-12 col-md-12 col-lg-9">
              <div class="row d-flex align-items-center">
                <div class="w-100 d-flex pt-1">
                  <div class="col-sm-6 col-md-2 col-lg-7">
                    <a (click)="viewPreviousTransaction()" class="text-sm mr-5" style="font-size: 10px">
                      <i class="fa fa-step-backward"></i> &nbsp; Back
                    </a>

                    <a (click)="viewNextTransaction()" class="text-sm mr-5" style="font-size: 10px">
                      Next &nbsp; <i class="fa fa-step-forward"></i>
                    </a>

                    <a
                      *ngIf="isOldTransaction"
                      (click)="getActiveTransaction()"
                      class="text-sm"
                      style="font-size: 10px"
                    >
                      Recent Transaction &nbsp; <i class="fa fa-undo"></i>
                    </a>
                  </div>
                  <!-- <div class="col-sm-6 col-md-2 col-lg-2">
                    
                  </div>
                  <div class="col-sm-6 col-md-2 col-lg-3 pl-0">
                    
                  </div> -->
                  <div class="col-sm-6 col-md-8 col-lg-5 d-flex justify-content-end align-items-center">
                    <p class="text-sm mb-0" style="font-size: 10px">
                      <span class="text-muted font-weight-bold">Transaction No: </span>
                      <span class="text-highlight font-weight-bold">
                        {{ pad(pos?.id, 10) }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-3 pr-0">
              <!-- *ngIf="!isOldTransaction" -->
              <div class="input-group" [formGroup]="oldPosTransactionFormGroup">
                <input
                  type="text"
                  class="form-control form-control-theme form-control-sm search text-dark"
                  style="background-color: rgb(247, 247, 247)"
                  placeholder="Type Transaction ID.."
                  required=""
                  formControlName="oldTransactionId"
                />

                <span class="input-group-append">
                  <button
                    class="btn no-border btn-sm"
                    style="background-color: rgb(203, 203, 202)"
                    type="button"
                    (click)="openPreviewOldTransactionModal(previewOldTransactionModal)"
                  >
                    <span class="d-flex text-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-search"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </span>
                  </button>
                </span>
              </div>

              <!-- <a >
                <div
                  class="btn btn-sm btn-info mini-buttons mr-1"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Back to home page"
                >
                  <i class="fa fa-reply"> </i>
                </div>
                <strong>Go Back to Active Transaction</strong>
              </a> -->
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12 mt-2 pl-0 pr-0">
              <div class="card w-100" style="height: 73vh; overflow-y: scroll">
                <app-pos-transaction-item
                  (emitRetransact)="emitRetransact()"
                  [pos]="pos"
                  [posTransactionId]="pad(pos?.id, 10)"
                ></app-pos-transaction-item>
              </div>
            </div>
          </div>
        </div>
        <!-- end of transaction items -->

        <!-- transaction details -->
        <div class="col-sm-12 col-md-6 col-lg-2">
          <div class="bg-dark p-2 d-flex justify-content-center align-items-center" style="border-radius: 5px">
            <div class="col-8 d-flex justify-content-center align-items-center pl-0 pr-0">
              <p class="text-sm mb-0">
                <span class="text-highlight font-weight-bold" style="font-size: 10px"> Transaction Details </span>
              </p>
            </div>
          </div>

          <div
            class="card mt-2"
            data-sr-id="12"
            style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s; height: 73vh"
          >
            <div class="card-body" style="overflow-y: scroll">
              <!-- <strong>Transaction Details</strong> 
              <hr /> -->
              <div class="pos-rlt" style="height: 70%; width: 100%">
                <div class="mb-2" *ngFor="let transaction of pos?.posTransactionItems">
                  <span class="text-highlight font-weight-bold" style="font-size: 10px">
                    {{ transaction.itemName }}
                  </span>
                  <br />
                  <span class="text-muted" style="font-size: 10px"
                    >₱ {{ transaction.amount | number: "1.2-2" }} x {{ transaction.quantity }}
                  </span>
                  <span class="font-weight-bold" style="float: right; font-size: 10px"
                    >₱ {{ getTotalByQuantity(transaction.amount, transaction.quantity) | number: "1.2-2" }}</span
                  >
                </div>
              </div>
            </div>

            <div class="card-body pt-0 pb-0 mt-0 ml-0">
              <div class="pos-rlt" style="width: 100%">
                <div>
                  <hr />
                  <div #containerQRDiv [hidden]="!isOldTransaction">
                    <ngx-qrcode
                      [elementType]="elementType"
                      [errorCorrectionLevel]="correctionLevel"
                      [value]="value"
                      cssClass="bshadow"
                      [width]="120"
                    ></ngx-qrcode>
                  </div>

                  <hr />
                  <span class="text-highlight font-weight-bold" style="font-size: 10px"> Subtotal </span>
                  <span class="text-highlight font-weight-bold" style="float: right; font-size: 10px">
                    ₱ {{ pos?.notMappedSubTotalAmount | number: "1.2-2" }}</span
                  >
                  <br />

                  <span class="text-muted" style="font-size: 10px"> Tax </span>
                  <span class="text-highlight font-weight-bold" style="float: right; font-size: 10px"
                    >₱ {{ pos?.notMappedTaxAmount | number: "1.2-2" }}</span
                  >

                  <hr />
                  <span class="text-highlight font-weight-bold mb-3" style="font-size: 10px"> Total </span>
                  <span class="text-highlight font-weight-bold mb-3" style="float: right; font-size: 10px">
                    ₱ {{ pos?.notMappedTotalAmount | number: "1.2-2" }}</span
                  >
                </div>
              </div>
            </div>

            <div class="card-body pt-0 pb-0 mt-0 ml-0">
              <div class="d-flex justify-content-center pr-0 pl-0" *ngIf="!isOldTransaction">
                <button class="btn btn-sm btn-primary rounded-pill mb-3 w-100" (click)="openProcessOrder(processOrder)">
                  Process Order
                </button>
              </div>
              <div class="d-flex justify-content-center pr-0 pl-0 mb-0" *ngIf="isOldTransaction">
                <button class="btn btn-sm btn-primary rounded-pill w-100" (click)="onReprintReceipt()">
                  Reprint Receipt
                </button>
              </div>
              <div class="d-flex justify-content-center pr-0 pl-0 mt-0 mb-3" *ngIf="isOldTransaction">
                <a (click)="onReprintReceiptA4()">Print via A4</a>
              </div>

              <!--  -->

              <!-- <button class="btn btn-sm btn-primary rounded-pill mb-5 w-100" printSectionId="print-section" ngxPrint>
                  Process Order
                </button>
  
                <div id="print-section"> 
                  <app-invoice></app-invoice>
                </div> -->
            </div>
          </div>

          <!-- <a
            onclick="window.open(document.URL, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');"
          >
            Open New Window
          </a> -->
        </div>
        <!-- end of transaction details -->

        <!-- product event -->
        <!-- <div class="col-sm-12 col-md-6 col-lg-2">
          
            <div
              class="card mt-2"
              data-sr-id="12"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s; height: 73vh"
            >

            <div class="leaderboard">
              <h1>
                  <svg class="ico-cup">
                      <use xlink:href="#cup"></use>
                  </svg>
                  Most Transacted Outlet
              </h1>
              <ol>
                  <li *ngFor="let outlet_score of topTenResult">
                    <mark>  {{ outlet_score.store_name }}</mark>
                      <small>{{ getPercentage(outlet_score.transaction_count) | number: "1.2-2" }}</small>
                  </li>
                 
              </ol>
          </div>
          
          
          <svg style="display: none;">
              <symbol id="cup" x="0px" y="0px" width="25px" height="26px" viewBox="0 0 25 26" enable-background="new 0 0 25 26" xml:space="preserve">
                  <path fill="#F26856" d="M21.215,1.428c-0.744,0-1.438,0.213-2.024,0.579V0.865c0-0.478-0.394-0.865-0.88-0.865H6.69
            C6.204,0,5.81,0.387,5.81,0.865v1.142C5.224,1.641,4.53,1.428,3.785,1.428C1.698,1.428,0,3.097,0,5.148
            C0,7.2,1.698,8.869,3.785,8.869h1.453c0.315,0,0.572,0.252,0.572,0.562c0,0.311-0.257,0.563-0.572,0.563
            c-0.486,0-0.88,0.388-0.88,0.865c0,0.478,0.395,0.865,0.88,0.865c0.421,0,0.816-0.111,1.158-0.303
            c0.318,0.865,0.761,1.647,1.318,2.31c0.686,0.814,1.515,1.425,2.433,1.808c-0.04,0.487-0.154,1.349-0.481,2.191
            c-0.591,1.519-1.564,2.257-2.975,2.257H5.238c-0.486,0-0.88,0.388-0.88,0.865v4.283c0,0.478,0.395,0.865,0.88,0.865h14.525
            c0.485,0,0.88-0.388,0.88-0.865v-4.283c0-0.478-0.395-0.865-0.88-0.865h-1.452c-1.411,0-2.385-0.738-2.975-2.257
            c-0.328-0.843-0.441-1.704-0.482-2.191c0.918-0.383,1.748-0.993,2.434-1.808c0.557-0.663,1-1.445,1.318-2.31
            c0.342,0.192,0.736,0.303,1.157,0.303c0.486,0,0.88-0.387,0.88-0.865c0-0.478-0.394-0.865-0.88-0.865
            c-0.315,0-0.572-0.252-0.572-0.563c0-0.31,0.257-0.562,0.572-0.562h1.452C23.303,8.869,25,7.2,25,5.148
            C25,3.097,23.303,1.428,21.215,1.428z M5.238,7.138H3.785c-1.116,0-2.024-0.893-2.024-1.99c0-1.097,0.908-1.99,2.024-1.99
            c1.117,0,2.025,0.893,2.025,1.99v2.06C5.627,7.163,5.435,7.138,5.238,7.138z M18.883,21.717v2.553H6.118v-2.553H18.883
            L18.883,21.717z M13.673,18.301c0.248,0.65,0.566,1.214,0.947,1.686h-4.24c0.381-0.472,0.699-1.035,0.947-1.686
            c0.33-0.865,0.479-1.723,0.545-2.327c0.207,0.021,0.416,0.033,0.627,0.033c0.211,0,0.42-0.013,0.627-0.033
            C13.195,16.578,13.344,17.436,13.673,18.301z M12.5,14.276c-2.856,0-4.93-2.638-4.93-6.273V1.73h9.859v6.273
            C17.43,11.638,15.357,14.276,12.5,14.276z M21.215,7.138h-1.452c-0.197,0-0.39,0.024-0.572,0.07v-2.06
            c0-1.097,0.908-1.99,2.024-1.99c1.117,0,2.025,0.893,2.025,1.99C23.241,6.246,22.333,7.138,21.215,7.138z" />
              </symbol>
          </svg>

              
    
             
            </div>
    
          
          </div> -->
        <!-- end of product event -->
      </div>
    </div>
  </div>
</div>

<!-- Modal for Creating Transaction -->

<ng-template #previewProductEventLeaderboard let-modal>
  <div class="modal-header">
    <h4 class="modal-title">GoVIPCenter Bonanza</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-product-event-leaderboard></app-product-event-leaderboard>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div> -->
</ng-template>

<ng-template #createTransactionModal let-modal>
  <div class="modal-header" [class.isPrinting]="printService.isPrinting">
    <h4 class="modal-title">{{ selectedApplication.value.thumbnail.title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-multisys
    *ngIf="selectedApplication.parameter == 'MULTISYS'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    (getShortcutApps)="getShortcutApps()"
    [application]="selectedApplication"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-multisys>

  <app-bayad
    *ngIf="selectedApplication.parameter == 'BAYAD_CENTER'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    (getShortcutApps)="getShortcutApps()"
    [application]="selectedApplication"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-bayad>

  <app-multisys
    *ngIf="selectedApplication.parameter == 'MULTISYS_SC'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
  ></app-multisys>

  <app-ecpay
    *ngIf="selectedApplication.parameter == 'ECPay Bills'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    (getShortcutApps)="getShortcutApps()"
    [application]="selectedApplication"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-ecpay>

  <app-ecpay
    *ngIf="selectedApplication.parameter == 'ECPay Bills SC'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-ecpay>

  <app-ectopup
    *ngIf="selectedApplication.parameter == 'ECPay Airtime'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-ectopup>

  <app-ectopup
    *ngIf="selectedApplication.parameter == 'EC_TOPUP_SC'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
  ></app-ectopup>

  <app-eccash
    *ngIf="selectedApplication.parameter == 'ECPay Remittance'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    (getShortcutApps)="getShortcutApps()"
    [application]="selectedApplication"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-eccash>

  <app-eccash
    *ngIf="selectedApplication.parameter == 'EC_CASH_SC'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
    [shortcutAccumulator]="shortcutAccumulator"
  ></app-eccash>

  <app-ec-sss-validate
    *ngIf="selectedApplication.parameter == 'ECPAY_SSS_VALIDATE'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-ec-sss-validate>

  <app-cti
    *ngIf="selectedApplication.parameter == 'Smart Eload'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-cti>

  <app-cti
    *ngIf="selectedApplication.parameter == 'Smart Eload SC'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
  ></app-cti>

  <app-ugi
    *ngIf="selectedApplication.parameter == 'UGI'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-ugi>

  <app-ugi
    *ngIf="selectedApplication.parameter == 'UGI SC'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    [shortcutCode]="selectedShortCut"
  ></app-ugi>

  <app-vip-protect
    *ngIf="selectedApplication.parameter == 'VIP Protect'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-vip-protect>

  <app-service-charge
    *ngIf="selectedApplication.parameter == 'SERVICE CHARGE'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-service-charge>

  <app-instapay
    *ngIf="selectedApplication.parameter == 'Instapay '"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    provider="INSTAPAY"
  ></app-instapay>

  <app-instapay
    *ngIf="selectedApplication.parameter == 'PESONet i'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
    provider="PESONET"
  ></app-instapay>

  <app-sunlife-grepa
    *ngIf="selectedApplication.parameter == 'Sunlife Grepa'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-sunlife-grepa>

  <app-ibayad-meralco
    *ngIf="selectedApplication.parameter == 'Meralco'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-ibayad-meralco>

  <app-standard-insurance
    *ngIf="selectedApplication.parameter == 'Standard Insurance'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-standard-insurance>

  <app-govipx-topup
    *ngIf="selectedApplication.parameter == 'GOVIPX_TOPUP'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-govipx-topup>

  <app-govipx-cash-out
    *ngIf="selectedApplication.parameter == 'GOVIPX_CASHOUT'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-govipx-cash-out>

  <app-paygram
    *ngIf="selectedApplication.parameter == 'PAYGRAM'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-paygram>

  <app-external-partner
    *ngIf="selectedApplication.parameter == 'Sendah Direct'"
    urlPath="https://portal.sendahdirect.com/login"
  ></app-external-partner>

  <app-external-partner
    *ngIf="selectedApplication.parameter == 'Sendah Remit'"
    urlPath="https://partners.sendahremit.com/login/"
  ></app-external-partner>

  <app-netbank
    *ngIf="selectedApplication.parameter == 'NetBank'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-netbank>

  <app-spx-rider-remittance
    *ngIf="selectedApplication.parameter == 'SPX Rider Remit'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-spx-rider-remittance>

  <app-bayad
    *ngIf="selectedApplication.parameter == 'Bayad'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-bayad>

  <app-igloo
    *ngIf="selectedApplication.parameter == 'Igloo'"
    (closeModal)="closeModal()"
    [application]="selectedApplication"
  ></app-igloo>

  <app-cebuana
    *ngIf="selectedApplication.parameter == 'Cebuana Padala'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    [application]="selectedApplication"
  ></app-cebuana>

  <app-jnt
    *ngIf="selectedApplication.parameter == 'J&T'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    [application]="selectedApplication"
  ></app-jnt>

  <app-phpay
    *ngIf="selectedApplication.parameter == 'Cardless Withdrawal'"
    (closeModal)="closeModal()"
    (closeOnly)="closeOnly()"
    [application]="selectedApplication"
  ></app-phpay>

  <!-- <app-external-partner
  *ngIf="selectedApplication.parameter == 'Converge Flexibiz'"
  urlPath="https://legacy.gofiber.ph/applicant#/application-tpa"
></app-external-partner> -->

  <div
    class="modal-body"
    *ngIf="
      selectedApplication.parameter != 'MULTISYS' &&
      selectedApplication.parameter != 'SERVICE CHARGE' &&
      selectedApplication.parameter != 'ECPay Bills' &&
      selectedApplication.parameter != 'ECPay Remittance' &&
      selectedApplication.parameter != 'ECPay Airtime' &&
      selectedApplication.parameter != 'ECPAY_SSS_VALIDATE' &&
      selectedApplication.parameter != 'Smart Eload' &&
      selectedApplication.parameter != 'Western Guarantee' &&
      selectedApplication.parameter != 'Instapay ' &&
      selectedApplication.parameter != 'PESONet i' &&
      selectedApplication.parameter != 'VIP Protect' &&
      selectedApplication.parameter != 'Sunlife Grepa' &&
      selectedApplication.parameter != 'Meralco' &&
      selectedApplication.parameter != 'Sendah Direct' &&
      selectedApplication.parameter != 'Sendah Remit' &&
      selectedApplication.parameter != 'MULTISYS_SC' &&
      selectedApplication.parameter != 'ECPay Bills SC' &&
      selectedApplication.parameter != 'Smart Eload SC' &&
      selectedApplication.parameter != 'EC_CASH_SC' &&
      selectedApplication.parameter != 'EC_TOPUP_SC' &&
      selectedApplication.parameter != 'Standard Insurance' &&
      selectedApplication.parameter != 'GOVIPX_TOPUP' &&
      selectedApplication.parameter != 'GOVIPX_CASHOUT' &&
      selectedApplication.parameter != 'NetBank' &&
      selectedApplication.parameter != 'SPX Rider Remit' &&
      selectedApplication.parameter != 'BAYAD_CENTER' &&
      selectedApplication.parameter != 'UGI' &&
      selectedApplication.parameter != 'Igloo' &&
      selectedApplication.parameter != 'Cebuana Padala' &&
      selectedApplication.parameter != 'J&T' &&
      selectedApplication.parameter != 'PAYGRAM' &&
      selectedApplication.parameter != 'Cardless Withdrawal'
    "
  >
    <h1>Product is on going development.</h1>
  </div>
</ng-template>

<ng-template #previewOldTransactionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Old Transaction</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="p-4 text-center">
      <p>
        Click proceed to preview transaction ID :
        <span class="text-dark font-weight-bold"
          >{{ oldPosTransactionFormGroup.controls.oldTransactionId.value }}
        </span>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="getOldTransaction()">Proceed</button>
  </div>
</ng-template>

<!-- *ngIf="isprinting" -->
<ng-template #processOrder let-modal>
  <div>
    <div class="modal-header">
      <h4 class="modal-title">Process Order</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-4">
      <form class="" role="form" action="dashboard.html">
        <app-textbox-currency
          [parent-form-group]="posTransactionFormGroup"
          tab-index-start="0"
          control-name="subTotalAmount"
          [error]="posTransactionFormGroup?.controls.subTotalAmount?.errors | estatus"
          [error-message]="posTransactionFormGroup?.controls.subTotalAmount?.errors | econtent"
          read-only="true"
          no-special-char="false"
          label="Sub Total"
          placeholder="0.00"
          max-length="20"
        >
        </app-textbox-currency>

        <app-textbox-currency
          [parent-form-group]="posTransactionFormGroup"
          tab-index-start="0"
          control-name="taxAmount"
          [error]="posTransactionFormGroup?.controls.taxAmount?.errors | estatus"
          [error-message]="posTransactionFormGroup?.controls.taxAmount?.errors | econtent"
          read-only="true"
          no-special-char="false"
          label="Tax Amount"
          placeholder="0.00"
          max-length="20"
        >
        </app-textbox-currency>

        <app-textbox-currency
          [parent-form-group]="posTransactionFormGroup"
          tab-index-start="0"
          control-name="totalAmount"
          [error]="posTransactionFormGroup?.controls.totalAmount?.errors | estatus"
          [error-message]="posTransactionFormGroup?.controls.totalAmount?.errors | econtent"
          read-only="true"
          no-special-char="false"
          label="Total Amount"
          placeholder="0.00"
          max-length="50"
        >
        </app-textbox-currency>

        <app-textbox-currency
          [parent-form-group]="posTransactionFormGroup"
          tab-index-start="0"
          control-name="cashTendered"
          [error]="posTransactionFormGroup?.controls.cashTendered?.errors | estatus"
          [error-message]="posTransactionFormGroup?.controls.cashTendered?.errors | econtent"
          read-only="false"
          number-only="false"
          label="Cash Tendered"
          placeholder="0.00"
          max-length="50"
        >
        </app-textbox-currency>

        <app-textbox-currency
          [parent-form-group]="posTransactionFormGroup"
          tab-index-start="0"
          control-name="cashChange"
          [error]="posTransactionFormGroup?.controls.cashChange?.errors | estatus"
          [error-message]="posTransactionFormGroup?.controls.cashChange?.errors | econtent"
          read-only="true"
          number-only="false"
          label="Change"
          placeholder="0.00"
          max-length="50"
        >
        </app-textbox-currency>

        <div class="d-flex justify-content-end">
          <!-- <div class="d-flex align-items-center mr-2">
            <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
            <label class="form-check-label text-muted" for="flexCheckDefault"> Print via A4 </label>
          </div> -->

          <!-- <button type="button" class="btn btn-primary mr-2 right pull-right" (click)="printPOSReceipt()">
            <i class="fa fa-print"></i> &nbsp; Print
          </button> -->

          <button
            type="button"
            class="btn btn-info right pull-right"
            appDebounceClick
            [debounceTime]="500"
            (debounceClick)="completeTransaction()"
          >
            <i class="fa fa-save"></i> &nbsp; Proceed
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!-- <ng-template #viewDetailsModal let-modal>
  <div class="modal-body">
    <div id="print-section" class="d-flex justify-content-center">
      <app-invoice></app-invoice>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-sm rounded-pill btn-danger mt-2 w-100 mr-5 ml-5" printSectionId="print-section" ngxPrint>
        Print
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
        <label class="form-check-label text-muted" for="flexCheckDefault"> Print via A4 </label>
      </div>
    </div>
  </div>
</ng-template> -->

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="20px"
  />
</div>

<ng-template #receiptModal let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->

  <!-- <div class="modal-body"> -->
  <!-- </div> -->
</ng-template>
<!-- ############ Main END-->
<!-- </div> -->

<ng-template #announcementModal let-modal>
  <div class="modal-header mb-0 pb-0">
    <h4 class="modal-title">JuanPay Announcement</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mt-0 pt-0" *ngIf="announcementString">
    <div [innerHtml]="announcementString"></div>
  </div>
</ng-template>

<ng-template #addSunLife let-modal>
  <div id="modal">
    <div class="modal-content">
      <div class="modal-body">
        <div class="p-4 text-center">
          <img
            src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file29815894-3e00-428c-87da-5a55069de2bf.png"
            width="80%"
          />
          <br />
          <br />
          <h5>Add JuanPay Accident Care Lite.</h5>
          <h6>
            As a loyal customer, mayroon po kaming libreng insurance para sa inyo na nagbibigay ng 7-days coverage
            againt accidental death and dismemberment worth PHP 10,000. Pa fill-out na lang po nitong form ma'am/sir
            while waiting po tayo sa resibo. Salamat po.
          </h6>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="closeModalSunlife()">Close</button>
        <button type="button" (click)="openModalSunlife()" class="btn btn-primary">Proceed</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</ng-template>

<div id="chatButton"></div>
