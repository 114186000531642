<app-loader *ngIf="processUpdate"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Transaction History</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of transactions</small>
        </div>
        <div class="flex"></div>
        <div>
          <div *ngIf="isDataNotNull">
            <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
              <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content" id="page-content">
      <div class="padding">
        <div class="toolbar" [formGroup]="searchByUsernameFormGroup">
          <!-- Filters -->

          <div class="card p-2 mb-2 w-100" style="background-color: #182038">
            <div class="row" [formGroup]="searchFormGroup">
              <div class="col-sm-4 mb-2 pr-0">
                <ng-select
                  [placeholder]="'Select Provider'"
                  [items]="providersOption"
                  [allowClear]="true"
                  style="width: 100%"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="provider"
                ></ng-select>
              </div>

              <div class="col-sm-3 mb-2 pr-0">
                <ng-select
                  [items]="billerOption"
                  [placeholder]="'Select Biller'"
                  [allowClear]="true"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="biller"
                >
                </ng-select>
              </div>

              <div class="col-sm-3 mb-2 pr-0">
                <ng-select
                  [items]="userIdFilterOptions"
                  [placeholder]="'Select Username'"
                  [allowClear]="true"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="userId"
                ></ng-select>
              </div>

              <div class="col-sm-2 mb-2">
                <ng-select
                  [items]="statusOPtion"
                  [placeholder]="'Select Status'"
                  [allowClear]="true"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="status"
                ></ng-select>
              </div>

              <div class="col-sm-4 pr-0">
                <input
                  type="text"
                  placeholder="Date Range"
                  class="form-control form-control-sm"
                  bsDaterangepicker
                  formControlName="dateRange"
                  style="background-color: white; color: black"
                  [bsConfig]="{
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-sm search"
                    placeholder="Account No"
                    formControlName="accountNo"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-sm search"
                    placeholder="Request ID"
                    formControlName="requestId"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-2">
                <button class="btn btn-sm btn-primary w-100" (click)="getTransaction()">
                  <i class="fa fa-search"></i> &nbsp; Search
                </button>
              </div>
              <div class="col-2"></div>
            </div>
          </div>

          <!-- List Toolbar Form -->
          <form class="form-inline"></form>

          <!-- Search Button -->
        </div>

        <app-cti-transactions *ngIf="provider == 'CTI'" [formGroupSearch]="searchFormGroup"> </app-cti-transactions>
        <app-multisys-transactions *ngIf="provider == 'MULTISYS'" [formGroupSearch]="searchFormGroup">
        </app-multisys-transactions>
        <app-ecpay-bill-transactions *ngIf="provider == 'ECPAY'" [formGroupSearch]="searchFormGroup">
        </app-ecpay-bill-transactions>
        <app-ecpay-cash-transactions *ngIf="provider == 'ECCASH'" [formGroupSearch]="searchFormGroup">
        </app-ecpay-cash-transactions>
        <app-ecpay-topup-transactions *ngIf="provider == 'ECTOPUP'" [formGroupSearch]="searchFormGroup">
        </app-ecpay-topup-transactions>
        <app-ibayad-transfer-transactions *ngIf="provider == 'INSTAPAY'" [formGroupSearch]="searchFormGroup">
        </app-ibayad-transfer-transactions>
        <app-ibayad-transfer-transactions *ngIf="provider == 'PESONET'" [formGroupSearch]="searchFormGroup">
        </app-ibayad-transfer-transactions>
        <app-vip-protect-bill-transactions *ngIf="provider == 'VIP_PROTECT'" [formGroupSearch]="searchFormGroup">
        </app-vip-protect-bill-transactions>
        <app-ibayad-meralco-transactions *ngIf="provider == 'IBayadMeralco'" [formGroupSearch]="searchFormGroup">
        </app-ibayad-meralco-transactions>
        <app-sunlife-grepa-transactions *ngIf="provider == 'SUNLIFE'" [formGroupSearch]="searchFormGroup">
        </app-sunlife-grepa-transactions>
        <app-spx-remittance-transactions *ngIf="provider == 'SPX_REMITTANCE'" [formGroupSearch]="searchFormGroup">
        </app-spx-remittance-transactions>
        <app-netbank-transactions *ngIf="provider == 'NetBank'" [formGroupSearch]="searchFormGroup">
        </app-netbank-transactions>
        <app-bayad-transactions *ngIf="provider == 'Bayad'" [formGroupSearch]="searchFormGroup">
        </app-bayad-transactions>
        <app-standard-insurance-transactions
          *ngIf="provider == 'Standard Insurance'"
          [formGroupSearch]="searchFormGroup"
        >
        </app-standard-insurance-transactions>
        <app-ugi-transactions *ngIf="provider == 'UGI'" [formGroupSearch]="searchFormGroup"> </app-ugi-transactions>
        <app-igloo-transactions *ngIf="provider == 'Igloo'" [formGroupSearch]="searchFormGroup">
        </app-igloo-transactions>
        <app-igloo-ctpl-transactions *ngIf="provider == 'Igloo CTPL'" [formGroupSearch]="searchFormGroup">
        </app-igloo-ctpl-transactions>
        <app-cebuana-transactions *ngIf="provider == 'Cebuana Padala'" [formGroupSearch]="searchFormGroup">
        </app-cebuana-transactions>
        <app-cebuana-payout-transactions *ngIf="provider == 'Cebuana Payout'" [formGroupSearch]="searchFormGroup">
        </app-cebuana-payout-transactions>
        <app-paygram-transactions *ngIf="provider == 'Paygram'" [formGroupSearch]="searchFormGroup">
        </app-paygram-transactions>
        <app-jnt-transactions *ngIf="provider == 'J&T'" [formGroupSearch]="searchFormGroup"> </app-jnt-transactions>
        <app-phpay-transactions *ngIf="provider == 'Cardless Withdrawal'" [formGroupSearch]="searchFormGroup">
        </app-phpay-transactions>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
