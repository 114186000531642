<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 6">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Igloo</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 || activeTab == 4 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Customer Details</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 || activeTab == 5 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >3</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 || active == 5 ? 'active' : ''">
            <img
              [src]="application.value.thumbnail.logo"
              height="50"
              class="img-responsive"
              style="object-fit: contain"
            />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3" [formGroup]="iglooPolicyFormGroup">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div>
                <img
                  [src]="application.value.thumbnail.logo"
                  height="100"
                  class="img-responsive"
                  style="object-fit: contain"
                />
              </div>
              <div class="d-flex justify-content-center align-items-center pl-3 pr-3">
                <h5>
                  We are an insurance technology company that provides people-first insurance services that are simple
                  to understand – easy to use, reasonably priced, honest and relevant. We are constantly looking for
                  new, smarter ways to eliminate inefficiencies such as time-consuming procedures, small print items. It
                  is about using advanced design and technology.
                </h5>
              </div>
            </div>

            <div class="col-sm-6 col-md-6" style="float: left">
              <h4>Choose Your Insurance Plan</h4>
              <hr />

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Insurance Plan: </label>
                  </div>
                  <div class="col-sm-8">
                    <select
                      formControlName="productType"
                      (change)="setSelectedProduct()"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let productDetail of productDetails" [value]="productDetail.id">
                        {{ productDetail.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="selectedProduct">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Product Type: </label>
                  </div>
                  <div class="col-sm-8">
                    <select
                      formControlName="productKey"
                      (change)="setSelectedProductType()"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of selectedProductTypes" [value]="type">{{ type }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="selectedProductType">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Product Plan: </label>
                  </div>
                  <div class="col-sm-8">
                    <select
                      formControlName="planKey"
                      (change)="setSelectedPlan($event)"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let plan of selectedProductTypePlans" [value]="plan.plan">{{ plan.plan }}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Coverage Period: </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                      formControlName="coveragePeriod"
                      readonly="read-only"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Wallet Deduction Amount: </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                      [value]="walletDeduction"
                      readonly="read-only"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Branch Share Amount: </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                      formControlName="outletShare"
                      [value]="makeMoney(iglooPolicyFormGroup.controls.outletShare.value)"
                      readonly="read-only"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Amount to be paid by Customer: </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                      [value]="makeMoney(iglooPolicyFormGroup.controls.amount.value)"
                      readonly="read-only"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-4">
                    <label>Amount of Insurance: </label>
                  </div>
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      tabindex="-1"
                      formControlName="amount"
                      [value]="makeMoney(iglooPolicyFormGroup.controls.amount.value)"
                      aria-hidden="true"
                      readonly="read-only"
                    />
                  </div>
                </div>
              </div>

              <!-- <div class="form-group">
                    <div  class="form-group row">
                    <div class="col-sm-4">
                    <label>Policy Holder Name: </label>
                </div>
                    <div class="col-sm-8">
                   <strong>ACM Business Solutions, Inc.</strong>
                  </div></div>
                </div> -->

              <!-- <div *ngIf="productDetail" class="form-group">
                    <div  class="form-group row">
                    <div class="col-sm-12">
                    <label>Insurance Plan Details:</label>
                    </div>
                    <div class="col-sm-12">
                        <div *ngFor="let meta of productDetail?.details">
                            <strong>*  {{meta.value}}</strong>
                        </div>
                    </div>
                    </div>
                </div> -->
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="detailsTab">
          <div class="row">
            <!-- <div class="col-sm-4 col-md-4" style="float:left" [formGroup]="holderFormGroup">

                <h4>Policy Holder</h4>
                <hr/>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="0"
                    control-name="fullName"
                    read-only="false"
                    [no-special-char]="false"
                    label="Full Name *"
                    placeholder="Lastname, Firstname Middlename"
                    max-length="50"
                >
                </app-textbox>

                <div class="form-group">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label class="text-muted">Gender: </label>
                    </div>
                    <div class="col-sm-9">
                      <select formControlName="gender"
                        class="form-control " tabindex="-1" aria-hidden="true">
                        <option value="">Choose...</option>
                        <option *ngFor="let gender of genders" [value]="gender.id">
                          {{ gender.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <app-datepicker
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="1"
                    control-name="dateOfBirth"
                    read-only="false"
                    label="Birth Date *"
                    placeholder="Birth Date"
                >
                </app-datepicker>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="occupation"
                    read-only="false"
                    [no-special-char]="false"
                    label="Occupation"
                    placeholder="Occupation"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="phoneAreaCode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Area Code"
                    placeholder="+63"
                    max-length="3"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="phoneNumber"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Number"
                    placeholder="9123456789"
                    max-length="10"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="email"
                    read-only="false"
                    [no-special-char]="false"
                    label="Email *"
                    placeholder="Juan@emmail.com"
                    max-length="50"
                >
                </app-textbox>

                <app-textarea
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="4"
                    control-name="addressLine1"
                    read-only="false"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                >
                </app-textarea>

                <app-textarea
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="4"
                    control-name="addressLine2"
                    read-only="false"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                >
                </app-textarea>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="province"
                    read-only="false"
                    [no-special-char]="false"
                    label="Province"
                    placeholder="Province"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="city"
                    read-only="false"
                    [no-special-char]="false"
                    label="City"
                    placeholder="City"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="holderFormGroup"
                    tab-index-start="3"
                    control-name="postcode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Post Code"
                    placeholder="Post Code"
                    max-length="50"
                >
                </app-textbox>
              </div> -->

            <div class="col-6">
              <div class="card">
                <h4>Insurance Details</h4>
                <hr />

                <div class="list list-row">
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Insurance Plan</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{
                          iglooPolicyFormGroup.controls.productType.value == "PA"
                            ? "Personal Accident"
                            : "Compulsory Third Party Liability"
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Product Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ iglooPolicyFormGroup.controls.productKey.value }}
                      </div>
                    </div>
                  </div>
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Product Plan</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        <!-- {{ vipProtectFormGroup.controls.birthDate.value | date: "yyyy-MM-dd"  }} -->
                        {{ iglooPolicyFormGroup.controls.planKey.value }}
                      </div>
                    </div>
                  </div>
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Coverage Period</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ iglooPolicyFormGroup.controls.coveragePeriod.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ iglooPolicyFormGroup.controls.amount.value | number: "1.2-2" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6" style="float: left" [formGroup]="insuredFormGroup">
              <h4>Insured</h4>
              <hr />

              <app-textbox
                [parent-form-group]="insuredFormGroup"
                tab-index-start="0"
                control-name="fullName"
                read-only="false"
                [no-special-char]="false"
                label="Full Name *"
                placeholder="Full Name"
                max-length="50"
              >
              </app-textbox>

              <!-- <div class="form-group">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label class="text-muted">Gender: </label>
                    </div>
                    <div class="col-sm-9">
                      <select formControlName="gender"
                        class="form-control " tabindex="-1" aria-hidden="true">
                        <option value="">Choose...</option>
                        <option *ngFor="let gender of genders" [value]="gender.id">
                          {{ gender.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div> -->

              <app-datepicker
                [parent-form-group]="insuredFormGroup"
                tab-index-start="1"
                control-name="dateOfBirth"
                read-only="false"
                label="Birth Date  *"
                placeholder="Birth Date"
              >
              </app-datepicker>

              <app-textbox
                [parent-form-group]="insuredFormGroup"
                tab-index-start="3"
                control-name="occupation"
                read-only="false"
                [no-special-char]="false"
                label="Occupation"
                placeholder="Occupation"
                max-length="50"
              >
              </app-textbox>

              <!-- <app-textbox
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="3"
                    control-name="phoneAreaCode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Area Code"
                    placeholder="+63"
                    max-length="3"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="3"
                    control-name="phoneNumber"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Number"
                    placeholder="9123456789"
                    max-length="10"
                >
                </app-textbox> -->

              <app-textbox
                [parent-form-group]="insuredFormGroup"
                tab-index-start="3"
                control-name="email"
                read-only="false"
                [no-special-char]="false"
                label="Email *"
                placeholder="Juan@emmail.com"
                max-length="50"
              >
              </app-textbox>

              <!-- <app-textarea
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="4"
                    control-name="addressLine1"
                    read-only="false"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                >
                </app-textarea>

                <app-textarea
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="4"
                    control-name="addressLine2"
                    read-only="false"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                >
                </app-textarea>

                <app-textbox
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="3"
                    control-name="province"
                    read-only="false"
                    [no-special-char]="false"
                    label="Province"
                    placeholder="Province"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="3"
                    control-name="city"
                    read-only="false"
                    [no-special-char]="false"
                    label="City"
                    placeholder="City"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="insuredFormGroup"
                    tab-index-start="3"
                    control-name="postcode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Post Code"
                    placeholder="Post Code"
                    max-length="50"
                >
                </app-textbox> -->
            </div>

            <!-- <div class="col-sm-4 col-md-4" style="float:left" [formGroup]="beneficiaryFormGroup">

                <h4>Beneficiary</h4>
                <hr/>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="0"
                    control-name="fullName"
                    read-only="false"
                    [no-special-char]="false"
                    label="Full Name *"
                    placeholder="Full Name"
                    max-length="50"
                >
                </app-textbox>

                <div class="form-group">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label class="text-muted">Gender: </label>
                    </div>
                    <div class="col-sm-9">
                      <select formControlName="gender"
                        class="form-control " tabindex="-1" aria-hidden="true">
                        <option value="">Choose...</option>
                        <option *ngFor="let gender of genders" [value]="gender.id">
                          {{ gender.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <app-datepicker
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="1"
                    control-name="dateOfBirth"
                    read-only="false"
                    label="Birth Date  *"
                    placeholder="Birth Date"
                >
                </app-datepicker>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="occupation"
                    read-only="false"
                    [no-special-char]="false"
                    label="Occupation"
                    placeholder="Occupation"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="phoneAreaCode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Area Code"
                    placeholder="+63"
                    max-length="3"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="phoneNumber"
                    read-only="false"
                    [no-special-char]="false"
                    label="Phone Number"
                    placeholder="9123456789"
                    max-length="10"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="email"
                    read-only="false"
                    [no-special-char]="false"
                    label="Email *"
                    placeholder="Juan@emmail.com"
                    max-length="50"
                >
                </app-textbox>

                <app-textarea
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="4"
                    control-name="addressLine1"
                    read-only="false"
                    label="Address Line 1"
                    placeholder="Address Line 1"
                >
                </app-textarea>

                <app-textarea
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="4"
                    control-name="addressLine2"
                    read-only="false"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                >
                </app-textarea>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="province"
                    read-only="false"
                    [no-special-char]="false"
                    label="Province"
                    placeholder="Province"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="city"
                    read-only="false"
                    [no-special-char]="false"
                    label="City"
                    placeholder="City"
                    max-length="50"
                >
                </app-textbox>

                <app-textbox
                    [parent-form-group]="beneficiaryFormGroup"
                    tab-index-start="3"
                    control-name="postcode"
                    read-only="false"
                    [no-special-char]="false"
                    label="Post Code"
                    placeholder="Post Code"
                    max-length="50"
                >
                </app-textbox>

                <div class="form-group" [formGroup]="iglooPolicyFormGroup">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label class="text-muted">Relation to Insured: </label>
                    </div>
                    <div class="col-sm-9">
                      <select formControlName="relationToInsured"
                        class="form-control " tabindex="-1" aria-hidden="true">
                        <option value="">Choose...</option>
                        <option *ngFor="let relation of relations" [value]="relation.id">
                          {{ relation.value }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
 
              </div> -->
          </div>
        </div>

        <div
          class="tab-pane"
          [ngClass]="activeTab == 4 ? 'active' : ''"
          id="ctplDetailsTab"
          [formGroup]="iglooCTPLPolicyFormGroup"
        >
          <div class="row">
            <div class="col-sm-6 col-md-6" style="float: left" [formGroup]="ctplHolderFormGroup">
              <h4>Policy Holder</h4>
              <hr />

              <!-- <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <label class="text-muted">ID Type: </label>
                  </div>
                  <div class="col-sm-9">
                    <select formControlName="idType"
                      class="form-control " tabindex="-1" aria-hidden="true">
                      <option value="">Choose...</option>
                      <option *ngFor="let idType of idTypes" [value]="idType.id">
                        {{ idType.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="0"
                  control-name="idNumber"
                  read-only="false"
                  [no-special-char]="false"
                  label="ID Number"
                  placeholder="ID Number"
                  max-length="50"
              >
              </app-textbox> -->

              <app-textbox
                [parent-form-group]="ctplHolderFormGroup"
                tab-index-start="0"
                control-name="fullname"
                read-only="false"
                [no-special-char]="false"
                label="Fullname *"
                placeholder="Fullname"
                max-length="100"
              >
              </app-textbox>

              <!-- <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="0"
                  control-name="firstName"
                  read-only="false"
                  [no-special-char]="false"
                  label="First Name *"
                  placeholder="First name"
                  max-length="50"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="0"
                  control-name="middleName"
                  read-only="false"
                  [no-special-char]="false"
                  label="Middle Name"
                  placeholder="Middle name"
                  max-length="50"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="0"
                  control-name="lastName"
                  read-only="false"
                  [no-special-char]="false"
                  label="Last Name *"
                  placeholder="Last name"
                  max-length="50"
              >
              </app-textbox>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <label class="text-muted">Gender: </label>
                  </div>
                  <div class="col-sm-9">
                    <select formControlName="gender"
                      class="form-control " tabindex="-1" aria-hidden="true">
                      <option value="">Choose...</option>
                      <option *ngFor="let gender of genders" [value]="gender.id">
                        {{ gender.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <app-datepicker
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="1"
                  control-name="dateOfBirth"
                  read-only="false"
                  label="Birth Date *"
                  placeholder="Birth Date"
              >
              </app-datepicker>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="occupation"
                  read-only="false"
                  [no-special-char]="false"
                  label="Occupation"
                  placeholder="Occupation"
                  max-length="50"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="phoneAreaCode"
                  read-only="false"
                  [no-special-char]="false"
                  label="Phone Area Code"
                  placeholder="+63"
                  max-length="3"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="phoneNumber"
                  read-only="false"
                  [no-special-char]="false"
                  label="Phone Number"
                  placeholder="9123456789"
                  max-length="10"
              >
              </app-textbox> -->

              <app-textbox
                [parent-form-group]="ctplHolderFormGroup"
                tab-index-start="3"
                control-name="email"
                read-only="false"
                [no-special-char]="false"
                label="Email *"
                placeholder="Juan@emmail.com"
                max-length="50"
              >
              </app-textbox>

              <app-textarea
                [parent-form-group]="ctplHolderFormGroup"
                tab-index-start="4"
                control-name="addressLine1"
                read-only="false"
                label="Address Line 1"
                placeholder="Address Line 1"
              >
              </app-textarea>

              <!-- <app-textarea
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="4"
                  control-name="addressLine2"
                  read-only="false"
                  label="Address Line 2"
                  placeholder="Address Line 2"
              >
              </app-textarea>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="province"
                  read-only="false"
                  [no-special-char]="false"
                  label="Province"
                  placeholder="Province"
                  max-length="50"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="city"
                  read-only="false"
                  [no-special-char]="false"
                  label="City"
                  placeholder="City"
                  max-length="50"
              >
              </app-textbox>

              <app-textbox
                  [parent-form-group]="ctplHolderFormGroup"
                  tab-index-start="3"
                  control-name="postcode"
                  read-only="false"
                  [no-special-char]="false"
                  label="Post Code"
                  placeholder="Post Code"
                  max-length="50"
              >
              </app-textbox> -->

              <div class="card-body">
                <div class="row row-sm" [formGroup]="fileFormGroup">
                  <div class="col-sm-6">
                    <div class="list list-row block">
                      <div class="list-item" data-id="20">
                        <div class="pointer" (click)="triggerFile(certificateOfRegistration)">
                          <span class="w-40 avatar gd-info">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            <input
                              #certificateOfRegistration
                              type="file"
                              (change)="onUploadFile($event, 'certificateOfRegistration')"
                              style="display: none"
                              accept="image/jpeg,image/png,application/pdf"
                            />
                          </span>
                        </div>
                        <div class="flex">
                          <a class="item-author text-color"
                            ><span class="text-danger font-weight-bold">*</span> &nbsp; Certificate of Registration</a
                          >
                          <div
                            class="item-except text-muted text-sm h-1x"
                            [ngClass]="{ 'is-invalid': submitted && fi.certificateOfRegistration.errors }"
                          >
                            <label
                              *ngIf="
                                !insuredVehicleFormGroup.controls.certificateOfRegistration.value &&
                                !fileFormGroup.controls.certificateOfRegistration.value
                              "
                              >Click the button to upload File...</label
                            >
                            <!-- <label *ngIf="insuredVehicleFormGroup.controls.certificateOfRegistration.value && !fileFormGroup.controls.certificateOfRegistration.value" 
                                            (click)="preview(viewImageModal, insuredVehicleFormGroup.controls.certificateOfRegistration.value)" 
                                            style="color:blue; cursor: pointer;"
                                            >
                                        <span class="fa fa-eye">&nbsp;</span>View File
                                        </label> -->
                            <label
                              *ngIf="fileFormGroup.controls.certificateOfRegistration.value"
                              style="color: blue; cursor: pointer"
                            >
                              <span class="fa fa-tag">&nbsp;</span>Next to preview file
                            </label>
                          </div>
                          <div *ngIf="submitted && fi.certificateOfRegistration.errors" class="invalid-feedback">
                            <div *ngIf="fi.certificateOfRegistration.errors">
                              Certificate of Registration is required
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="list list-row block">
                      <div class="list-item" data-id="20">
                        <div class="pointer" (click)="triggerFile(officialReceipt)">
                          <span class="w-40 avatar gd-info">
                            <i class="fa fa-upload" aria-hidden="true"></i>
                            <input
                              #officialReceipt
                              type="file"
                              (change)="onUploadFile($event, 'officialReceipt')"
                              style="display: none"
                              accept="image/jpeg,image/png,application/pdf"
                            />
                          </span>
                        </div>
                        <div class="flex">
                          <a class="item-author text-color"
                            ><span class="text-danger font-weight-bold">*</span> &nbsp; Official Receipt</a
                          >
                          <div
                            class="item-except text-muted text-sm h-1x"
                            [ngClass]="{ 'is-invalid': submitted && fi.officialReceipt.errors }"
                          >
                            <label
                              *ngIf="
                                !insuredVehicleFormGroup.controls.officialReceipt.value &&
                                !fileFormGroup.controls.officialReceipt.value
                              "
                              >Click the button to upload File...</label
                            >
                            <!-- <label *ngIf="insuredVehicleFormGroup.controls.officialReceipt.value && !fileFormGroup.controls.officialReceipt.value" 
                                          (click)="preview(viewImageModal, insuredVehicleFormGroup.controls.officialReceipt.value)" 
                                          style="color:blue; cursor: pointer;"
                                          >
                                      <span class="fa fa-eye">&nbsp;</span>View File
                                      </label> -->
                            <label
                              *ngIf="fileFormGroup.controls.officialReceipt.value"
                              style="color: blue; cursor: pointer"
                            >
                              <span class="fa fa-tag">&nbsp;</span>Next to preview file
                            </label>
                          </div>
                          <div *ngIf="submitted && fi.officialReceipt.errors" class="invalid-feedback">
                            <div *ngIf="fi.officialReceipt.errors">Official Receipt</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6" style="float: left" [formGroup]="insuredVehicleFormGroup">
              <h4>Insured Vehicle</h4>
              <hr />

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="0"
                control-name="bodyType"
                read-only="false"
                [no-special-char]="false"
                label="Body Type *"
                placeholder="Body Type"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="3"
                control-name="chassisNumber"
                read-only="false"
                [no-special-char]="false"
                label="Chassis Number *"
                placeholder="Chassis Number"
                max-length="50"
              >
              </app-textbox>

              <!-- <app-textbox
                  [parent-form-group]="insuredVehicleFormGroup"
                  tab-index-start="3"
                  control-name="color"
                  read-only="false"
                  [no-special-char]="false"
                  label="Color *"
                  placeholder="Color"
                  max-length="50"
              >
              </app-textbox> -->

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="3"
                control-name="engineNumber"
                read-only="false"
                [no-special-char]="false"
                label="Engine Number *"
                placeholder="Engine Number"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="3"
                control-name="make"
                read-only="false"
                [no-special-char]="false"
                label="Make *"
                placeholder="Make"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="3"
                control-name="mvFileNumber"
                read-only="false"
                [no-special-char]="false"
                label="MV File Number *"
                placeholder="MV File Number"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="insuredVehicleFormGroup"
                tab-index-start="4"
                control-name="plateNumber"
                read-only="false"
                label="Plate Number *"
                placeholder="Plate Number"
                max-length="50"
              >
              </app-textbox>

              <!-- <app-textbox
                  [parent-form-group]="insuredVehicleFormGroup"
                  tab-index-start="4"
                  control-name="series"
                  read-only="false"
                  label="Series *"
                  placeholder="Series"
                  max-length="50"
              >
              </app-textbox> -->

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <label class="text-muted">Vehicle Type *: </label>
                  </div>
                  <div class="col-sm-9">
                    <select formControlName="vehicleType" class="form-control" tabindex="-1" aria-hidden="true">
                      <option value="">Choose...</option>
                      <option *ngFor="let type of vehicleTypes" [value]="type.id">
                        {{ type.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="form-group row">
                  <div class="col-sm-3">
                    <label class="text-muted">Year Model *: </label>
                  </div>
                  <div class="col-sm-9">
                    <select formControlName="yearModel" class="form-control" tabindex="-1" aria-hidden="true">
                      <option value="">Choose...</option>
                      <option *ngFor="let year of years" [value]="year.id">
                        {{ year.value }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 3 ? 'active' : ''" id="newTransTab">
          <div class="form-group">
            <p>
              <strong>Igloo Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <hr />

            <div class="row">
              <div class="col-6">
                <div class="card">
                  <p><strong>Insurance Details</strong><br /></p>
                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Insurance Plan</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{
                            iglooPolicyFormGroup.controls.productType.value == "PA"
                              ? "Personal Accident"
                              : "Compulsory Third Party Liability"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Product Type</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooPolicyFormGroup.controls.productKey.value }}
                        </div>
                      </div>
                    </div>
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Product Plan</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooPolicyFormGroup.controls.planKey.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <p><strong>More Details</strong><br /></p>

                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Coverage Period</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooPolicyFormGroup.controls.coveragePeriod.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Outlet Share</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooPolicyFormGroup.controls.outletShare.value | number: "1.2-2" }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooPolicyFormGroup.controls.amount.value | number: "1.2-2" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group" [formGroup]="insuredFormGroup">
                  <p><strong>Insured Details</strong><br /></p>
                  <div class="card">
                    <div class="list list-row">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">{{ insuredFormGroup.controls.fullName?.value }}</div>
                        </div>
                      </div>

                      <!-- <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.gender?.value }}
                          </div>
                        </div>
                      </div> -->

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.email?.value }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.dateOfBirth?.value | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.occupation?.value }}
                          </div>
                        </div>
                      </div>

                      <!-- <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.phoneAreaCode?.value }} {{ insuredFormGroup.controls.phoneNumber?.value }} 
                          </div>
                        </div>
                      </div> 
  
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.addressLine1?.value }}
                          </div>
                        </div>
                      </div>
  
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.addressLine2?.value }}
                          </div>
                        </div>
                      </div>
  
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.province?.value }}
                          </div>
                        </div>
                      </div>
  
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.city?.value }}
                          </div>
                        </div>
                      </div>
  
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ insuredFormGroup.controls.postcode?.value }}
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- <div class="col-4">
              <div class="form-group" [formGroup]="ctplHolderFormGroup">
                <p>
                  <strong>Policy Holder Details</strong><br />
                </p>
                <div class="card">
                  <div class="list list-row">

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">{{ holderFormGroup.controls.fullName?.value }}</div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.gender?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.dateOfBirth?.value | date: "MMM dd, yyyy"  }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.occupation?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.phoneAreaCode?.value }} {{ holderFormGroup.controls.phoneNumber?.value }} 
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.email?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.addressLine1?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.addressLine2?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.province?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.city?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ holderFormGroup.controls.postcode?.value }}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-6">
              
            </div> -->

            <!-- <div class="col-4">
              <div class="form-group" [formGroup]="beneficiaryFormGroup">
                <p>
                  <strong>Beneficiary Details</strong><br />
                </p>
                <div class="card">
                  <div class="list list-row">
                    
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Relation to Insured</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">{{ iglooPolicyFormGroup.controls.relationToInsured?.value }}</div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">{{ beneficiaryFormGroup.controls.fullName?.value }}</div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.gender?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.dateOfBirth?.value | date: "MMM dd, yyyy"  }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.occupation?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.phoneAreaCode?.value }} {{ beneficiaryFormGroup.controls.phoneNumber?.value }} 
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.email?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.addressLine1?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.addressLine2?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.province?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.city?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ beneficiaryFormGroup.controls.postcode?.value }}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <hr />
          <div class="col-sm-12 col-md-12">
            <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
              <div class="item-feed h-2x text-center">ENTER PINCODE</div>
              <code-input
                [isCodeHidden]="true"
                [codeLength]="6"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)"
                [code]="code"
              >
              </code-input>
            </div>
          </div>
          <br />
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 5 ? 'active' : ''" id="CTPLConfirmationTab">
          <div class="form-group">
            <p>
              <strong>Igloo CTPL Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <hr />

            <div class="row">
              <div class="col-6">
                <p><strong>Insurance Details</strong><br /></p>
                <div class="card">
                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Insurance Plan</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{
                            iglooCTPLPolicyFormGroup.controls.productType.value == "PA"
                              ? "Personal Accident"
                              : "Compulsory Third Party Liability"
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Product Type</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooCTPLPolicyFormGroup.controls.productKey.value }}
                        </div>
                      </div>
                    </div>
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Product Plan</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          <!-- {{ vipProtectFormGroup.controls.birthDate.value | date: "yyyy-MM-dd"  }} -->
                          {{ iglooCTPLPolicyFormGroup.controls.planKey.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="card">
                  <p><strong>More Details</strong><br /></p>

                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Coverage Period</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooCTPLPolicyFormGroup.controls.coveragePeriod.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Outlet Share</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooCTPLPolicyFormGroup.controls.outletShare.value | number: "1.2-2" }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ iglooCTPLPolicyFormGroup.controls.amount.value | number: "1.2-2" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group" [formGroup]="ctplHolderFormGroup">
                <p><strong>Policy Holder Details</strong><br /></p>
                <div class="card">
                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">{{ ctplHolderFormGroup.controls.fullName?.value }}</div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ ctplHolderFormGroup.controls.email?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Address</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ ctplHolderFormGroup.controls.addressLine1?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="card-body">
                      <div class="row row-sm" [formGroup]="fileFormGroup">
                        <div class="col-sm-6">
                          <div class="list list-row block">
                            <div class="list-item" data-id="20">
                              <div class="pointer">
                                <span class="w-40 avatar gd-info"> </span>
                              </div>
                              <div class="flex">
                                <a class="item-author text-color">Certificate of Registration</a>
                                <div class="item-except text-muted text-sm h-1x">
                                  <label
                                    *ngIf="insuredVehicleFormGroup.controls.certificateOfRegistration.value"
                                    (click)="
                                      preview(
                                        viewImageModal,
                                        insuredVehicleFormGroup.controls.certificateOfRegistration.value
                                      )
                                    "
                                    style="color: blue; cursor: pointer"
                                  >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="list list-row block">
                            <div class="list-item" data-id="20">
                              <div class="pointer">
                                <span class="w-40 avatar gd-info"> </span>
                              </div>
                              <div class="flex">
                                <a class="item-author text-color">Official Receipt</a>
                                <div class="item-except text-muted text-sm h-1x">
                                  <label
                                    *ngIf="insuredVehicleFormGroup.controls.officialReceipt.value"
                                    (click)="
                                      preview(viewImageModal, insuredVehicleFormGroup.controls.officialReceipt.value)
                                    "
                                    style="color: blue; cursor: pointer"
                                  >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group" [formGroup]="insuredVehicleFormGroup">
                <p><strong>Insured Vehicle</strong><br /></p>
                <div class="card">
                  <div class="list list-row">
                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Body Type</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">{{ insuredVehicleFormGroup.controls.bodyType?.value }}</div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Chassis Number</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.chassisNumber?.value }}
                        </div>
                      </div>
                    </div>

                    <!-- <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Color</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.color?.value }}
                        </div>
                      </div>
                    </div> -->

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Engine Number</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.engineNumber?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Make</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.make?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">MV File Number</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.mvFileNumber?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">plateNumber</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.plateNumber?.value }}
                        </div>
                      </div>
                    </div>

                    <!-- <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Series</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.series?.value }}
                        </div>
                      </div>
                    </div> -->

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Vehicle Type</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.vehicleType?.value }}
                        </div>
                      </div>
                    </div>

                    <div class="list-item" data-id="19">
                      <div class="flex">
                        <div class="item-date text-muted text-sm d-none d-md-block">Year Model</div>
                      </div>
                      <div class="no-wrap">
                        <div class="item-feed h-2x">
                          {{ insuredVehicleFormGroup.controls.yearModel?.value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="col-sm-12 col-md-12">
            <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
              <div class="item-feed h-2x text-center">ENTER PINCODE</div>
              <code-input
                [isCodeHidden]="true"
                [codeLength]="6"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)"
                [code]="code"
              >
              </code-input>
            </div>
          </div>
          <br />
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" *ngIf="activeTab != 1" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 3 && activeTab != 5" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 3"
                appDebounceClick
                [debounceTime]="500"
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Igloo &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
              <a
                *ngIf="activeTab == 5"
                appDebounceClick
                [debounceTime]="500"
                (debounceClick)="processCTPLPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Igloo &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <img [src]="imagePreview" onerror="this.src='https://via.placeholder.com/64x64?text=VIP';" style="width: 100%" />
    </div>
  </div>
</ng-template>
