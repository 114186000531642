import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { PrintService } from 'src/data/services/web/print.service';
import { TransactionService } from 'src/data/services/web/transaction.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pos-transaction-item',
  templateUrl: './pos-transaction-item.component.html',
  styleUrls: ['./pos-transaction-item.component.less']
})
export class PosTransactionItemComponent implements OnInit {

  @Input() pos: any;
  @Input() isPrint: boolean = false;
  @Input() posTransactionId: boolean = false;
  @Input() showDetails: any = true;
  @ViewChild('viewMultisys') viewMultisys: TemplateRef<any>;
  @Output("emitRetransact") emitRetransact = new EventEmitter();

  public isA4 = new FormControl();
  public transactionType : any;
  public receipt: any;
  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;

  @ViewChild('containerQRDiv') containerQRDiv: ElementRef;
  @ViewChild('vipLogo') vipLogo: ElementRef;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://www.techiediaries.com/';

  constructor(private modalService : NgbModal, public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, private currencyPipe: CurrencyPipe, transactionService: TransactionService) {
      this.transactionService = transactionService;
     }
  
  ngOnInit(): void {
    if(this.showDetails != "false"){
      this.initReceipt();
      this.value = `${environment.WEB_URL}${this.pos.id}`;
    }
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  requestId;
  transaction;
  setSelectedTransaction(transaction: any) {
    if(this.showDetails != "false"){
      this.transaction = transaction;
      this.transactionType = this.transaction.transactionType;
      this.requestId = this.transaction.requestId;
      //debugger
      switch (this.transactionType){
        case "BILLS_PAYMENT_MULTISYS" : this.openSelectedTransactionDetail(this.viewMultisys); break;
        case "SERVICE_CHARGE" : this.openSelectedTransactionDetail(this.viewMultisys); break;
        case "BILLS_PAYMENT_CTI" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "BILLS_PAYMENT_ECPAY" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "VIP_PROTECT" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "SUNLIFE_GREPA" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CASH_ECPAY" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "TOP_UP_ECPAY" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "IBAYAD_TRANSFER" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "IBAYAD_MERALCO" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "STANDARD_INSURANCE" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "GOVIPX_CASHOUT" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "GOVIPX_TOPUP" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "SPX_RIDER_REMIT" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "BAYAD_CENTER" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "NETBANK_TRANSFER" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "UGI" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "IGLOO" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "IGLOO_CTPL" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CEBUANA_SEND" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CEBUANA_PAYOUT" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CEBUANA" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CEBUANA" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "PAYGRAM_PAY" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "PAYGRAM_REDEEM" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "JNT" : this.openSelectedTransactionDetail(this.viewMultisys); break;
         case "CARDLESS WITHDRAWAL" : this.openSelectedTransactionDetail(this.viewMultisys); break;

        // case "SSS_VALIDATE_ECPAY" : this.openSelectedTransactionDetail(this.viewMultisys); break;
      }
    }
    

  }

  //

  modalCreateTransaction:any;
  openSelectedTransactionDetail(viewModal) {
    this.modalCreateTransaction = this.modalService.open(viewModal, { centered: true, size: "xl" });
  }

  closeModal(){
   this.modalCreateTransaction.close();
   this.emitRetransact.emit();
  }

  //printReceipt(){

    //alert(JSON.stringify(this.pos));
    
    //this.printService.printDocument('invoice', this.pos.id);

  //}

  printReceipt(){
   
  }

  printPOSReceipt(){
    
  const pos = this.pos;
  const posTrans = pos.posTransactionItems;
  
  console.log("POS POS SPOS : " , pos);
  console.log("POS ITEM ITEM : " , posTrans);
  
     if(this.isA4.value){
      var newPosRowA4 = "";
      var billernameA4 = "";
      var billerCodeA4 = "";
      var accountNumberA4 = "";
      var dateA4 = "";
      var reqIdA4 = "";
      var quantityA4 = "";
      var amountA4 = "";
      var subtotal;
      var posTemplateA4 = "";
  
      for(let i = 0; i < pos.posTransactionItems.length; i++){
        if(posTrans[i].statusId == 2 || posTrans[i].statusId == 3){
          billernameA4 = posTrans[i].itemName;
          billerCodeA4 = posTrans[i].itemCode;
          accountNumberA4 = posTrans[i].accountNumber;
          dateA4 = this.datepipe.transform(posTrans[i].createdDate);
          quantityA4 = posTrans[i].quantity;
          reqIdA4 = posTrans[i].requestId;
          amountA4 = posTrans[i].amount;
      
          var posRow = (this.receipt[7].value).replace("{{Date}}", dateA4);
          posRow = (posRow).replace("{{BillerName}}", billernameA4);
          posRow = (posRow).replace("{{BillerCode}}", billerCodeA4);
          posRow = (posRow).replace("{{AccountNumber}}", accountNumberA4);
          posRow = (posRow).replace("{{RequestID}}", reqIdA4);
          posRow = (posRow).replace("{{Amount}}", this.makeMoney(amountA4));
    
          newPosRowA4 += posRow;
        }
      }
  
      posTemplateA4 = this.ReplaceValues(pos, 6);
      this.PrintReceipt(posTemplateA4, newPosRowA4);
  
     } else {
      var posTemplate = "";
      var newPosRow = "";
      var transType = "";
      var transName = "";
      var quantity = "";
      var amount = "";
      var accountNumber = "";
      
  
      for(let i = 0; i < pos.posTransactionItems.length; i++){
        if(posTrans[i].statusId == 2 || posTrans[i].statusId == 3){
          transType = posTrans[i].transactionType;
          transName = posTrans[i].itemName;
          quantity = posTrans[i].quantity;
          accountNumber = posTrans[i].accountNumber;
          amount = posTrans[i].amount;
       
          debugger
          var posRow = (this.receipt[4].value).replace("{{TrasactionType}}", transType);
          posRow = (posRow).replace("{{BillerName}}", transName);
          posRow = (posRow).replace("{{Quantity}}", quantity);
          posRow = (posRow).replace("{{BillAmount}}", this.makeMoney(amount));
          posRow = (posRow).replace("{{AccountNumber}}", accountNumber);
          if(transType == "CEBUANA_SEND" || transType == "CEBUANA_PAYOUT" || transType == "CEBUANA"){
            posRow = (posRow).replace("Customer Account Number", "Control No");
          } else if(transType == "JNT"){
            posRow = (posRow).replace("Customer Account Number", "Mail No");
          }
          
          newPosRow += posRow;
        }
      }
     
      posTemplate = this.ReplaceValues(pos, 2);
      this.PrintReceipt(posTemplate, newPosRow);
    }
  }
  
  ReplaceValues(pos: any, val: number){
    var logo = '<img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" border="0">';
    var cashier = pos.transactByUser.username;
    var createdDate = this.datepipe.transform(pos.completedDate, 'MMM-dd-yyyy');
    var transTime = this.datepipe.transform(pos.completedDate, 'hh:mm:ss a');
    var htlmQR = this.containerQRDiv.nativeElement.innerHTML;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;
    var TransNo = pos.id;
  
    
  
    var subTotal = this.makeMoney(pos.totalAmount);
    var tax = "0.00";

    var total = this.makeMoney(pos.totalAmount);
    var cashTendered = this.makeMoney(pos.cashTendered);
    var cashChange = this.makeMoney(pos.cashChange);
    
  
    var posTemplate = (this.receipt[val].value).replace("{{Cashier}}", cashier);
    //posRow = (posRow).replace("", htlmQR);
    posTemplate = posTemplate.replace("{{TransNo}}", TransNo);
    posTemplate = posTemplate.replace("{{receiptLogo}}", vipLogo);
    posTemplate = posTemplate.replace("{{dti}}", this.dti);
    posTemplate = posTemplate.replace("{{tinNO}}", this.tin);
    posTemplate = posTemplate.replace("{{address}}", this.address);
    posTemplate = posTemplate.replace("{{QRHtml}}", htlmQR);
    posTemplate = posTemplate.replace("{{Date}}", createdDate);
    posTemplate = posTemplate.replace("{{Time}}", transTime);
  
    posTemplate = posTemplate.replace("{{SubTotal}}", subTotal);
    posTemplate = posTemplate.replace("{{Tax}}", tax);
  
    posTemplate = posTemplate.replace("{{Total}}", total);
    posTemplate = posTemplate.replace("{{CashTendered}}", cashTendered);
    posTemplate = posTemplate.replace("{{Change}}", cashChange);
  
    return posTemplate;
  }
  
  PrintReceipt(NewStr: any, NewRows: any)
  {
      var finalStr = NewStr.replace("{{tablerows}}", NewRows);
  
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(finalStr);
  
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
  
      return true;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }
}
