<div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isPrint">
  <div class="card">
    <div class="list list-row">
      <div class="list-item" data-id="19">
        <div class="flex">
          <div class="item-date text-md d-none d-md-block color-red">
            <span class="text-dark">Transaction No: </span> <strong>{{ posTransactionId }}</strong>
          </div>
        </div>
        <div class="flex">
          <div class="item-feed h-2x">
            <a style="color: blue" (click)="printPOSReceipt()">Print Receipt <span class="fa fa-print"></span></a>
            <br />
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
              <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                Print via A4
              </label>
            </div>
          </div>
        </div>
        <div class="no-wrap">
          <div #containerQRDiv>
            <ngx-qrcode
              [elementType]="elementType"
              [errorCorrectionLevel]="correctionLevel"
              [value]="value"
              cssClass="bshadow"
              [width]="120"
            ></ngx-qrcode>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<table
  id="datatable"
  class="table table-theme table-row v-middle dataTable no-footer table-hover"
  role="grid"
  aria-describedby="datatable_info"
>
  <thead>
    <tr>
      <th data-sortable="true" data-field="Product" style="width: 20px">Date</th>
      <th data-sortable="true" data-field="Quantity">
        <div>Biller Details</div>
      </th>
      <th data-sortable="true" data-field="Price">
        <div>Paid Bill Amount</div>
      </th>
      <th data-sortable="true" data-field="Subtotal">
        <div>Quantity</div>
      </th>
      <th data-sortable="true" data-field="Status">
        <div>Via</div>
      </th>
      <th data-sortable="true" data-field="Status">
        <div>Status</div>
      </th>
    </tr>
  </thead>

  <!-- (click)="viewTransactionDetails(ii?.id)" -->
  <tbody>
    <tr
      *ngFor="let transaction of pos?.posTransactionItems"
      style="cursor: pointer"
      class="odd"
      role="row"
      (click)="setSelectedTransaction(transaction)"
    >
      <td style="width: 20px; padding-bottom: 2px">
        <p class="text-muted mb-0" style="font-size: 8px">{{ transaction.createdDate | date: "MMM" }}</p>
        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">
          {{ transaction.createdDate | date: "dd" }}
        </p>
        <p class="text-muted mt-0" style="font-size: 8px">{{ transaction.createdDate | date: "yyyy" }}</p>
      </td>

      <td style="padding-bottom: 2px">
        <!-- <small>{{ ii.product }}</small> -->
        <p class="mb-0" style="font-size: 12px">
          <span class="text-highlight font-weight-bold"> {{ transaction.itemName }} </span>
          ( <span class="font-highlighted"> {{ transaction.itemCode }} </span> )
        </p>
        <p class="text-muted mt-0 mb-0" style="font-size: 10px">
          Request ID:<span class="font-highlighted"> {{ transaction.requestId }} </span>
        </p>
        <p class="text-muted mt-0" style="font-size: 10px">
          Account No:<span class="font-highlighted"> {{ transaction.accountNumber }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted">₱ {{ transaction.amount | number: "1.2-2" }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted"> {{ transaction.quantity }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted"> {{ transaction.processorName }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <app-status-table-data [status]="transaction.statusId"></app-status-table-data>
        </p>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #viewMultisys let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[{{ transactionType }}] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-multisys-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'BILLS_PAYMENT_MULTISYS'"
      [requestId]="requestId"
    ></app-multisys-transaction>
    <app-service-charge-transaction
      *ngIf="transactionType == 'SERVICE_CHARGE'"
      [requestId]="requestId"
    ></app-service-charge-transaction>
    <app-vip-protect-transaction
      *ngIf="transactionType == 'VIP_PROTECT'"
      [requestId]="requestId"
    ></app-vip-protect-transaction>
    <app-sunlife-grepa-transaction
      *ngIf="transactionType == 'SUNLIFE_GREPA'"
      [requestId]="requestId"
    ></app-sunlife-grepa-transaction>
    <app-ecpay-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'BILLS_PAYMENT_ECPAY'"
      [requestId]="requestId"
    >
    </app-ecpay-transaction>
    <app-eccash-transaction (closeModal)="closeModal()" *ngIf="transactionType == 'CASH_ECPAY'" [requestId]="requestId">
    </app-eccash-transaction>
    <app-ectopup-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'TOP_UP_ECPAY'"
      [requestId]="requestId"
    >
    </app-ectopup-transaction>
    <app-cti-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'BILLS_PAYMENT_CTI'"
      [requestId]="requestId"
    >
    </app-cti-transaction>

    <app-instapay-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'IBAYAD_TRANSFER'"
      [requestId]="requestId"
    >
    </app-instapay-transaction>

    <app-ibayad-meralco-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'IBAYAD_MERALCO'"
      [requestId]="requestId"
    >
    </app-ibayad-meralco-transaction>

    <app-standard-insurance-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'STANDARD_INSURANCE'"
      [requestId]="requestId"
    >
    </app-standard-insurance-transaction>

    <app-govipx-cashout-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'GOVIPX_CASHOUT'"
      [requestId]="requestId"
    >
    </app-govipx-cashout-transaction>

    <app-govipx-topup-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'GOVIPX_TOPUP'"
      [requestId]="requestId"
    >
    </app-govipx-topup-transaction>

    <app-spx-remittance-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'SPX_RIDER_REMIT'"
      [requestId]="requestId"
    >
    </app-spx-remittance-transaction>

    <app-bayad-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'BAYAD_CENTER'"
      [requestId]="requestId"
    >
    </app-bayad-transaction>

    <app-netbank-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'NETBANK_TRANSFER'"
      [requestId]="requestId"
    >
    </app-netbank-transaction>

    <app-ugi-transaction (closeModal)="closeModal()" *ngIf="transactionType == 'UGI'" [requestId]="requestId">
    </app-ugi-transaction>

    <app-igloo-transaction (closeModal)="closeModal()" *ngIf="transactionType == 'IGLOO'" [requestId]="requestId">
    </app-igloo-transaction>

    <app-igloo-ctpl-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'IGLOO_CTPL'"
      [requestId]="requestId"
    >
    </app-igloo-ctpl-transaction>

    <app-cebuana-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'CEBUANA_SEND'"
      [requestId]="requestId"
    >
    </app-cebuana-transaction>

    <app-cebuana-transaction (closeModal)="closeModal()" *ngIf="transactionType == 'CEBUANA'" [requestId]="requestId">
    </app-cebuana-transaction>

    <app-cebuana-payout-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'CEBUANA_PAYOUT'"
      [requestId]="requestId"
    >
    </app-cebuana-payout-transaction>

    <app-paygram-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'PAYGRAM_REDEEM'"
      [requestId]="requestId"
    >
    </app-paygram-transaction>

    <app-paygram-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'PAYGRAM_PAY'"
      [requestId]="requestId"
    >
    </app-paygram-transaction>

    <app-jnt-transaction (closeModal)="closeModal()" *ngIf="transactionType == 'JNT'" [requestId]="requestId">
    </app-jnt-transaction>

    <app-phpay-transaction
      (closeModal)="closeModal()"
      *ngIf="transactionType == 'CARDLESS WITHDRAWAL'"
      [requestId]="requestId"
    >
    </app-phpay-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="20px"
  />
</div>
