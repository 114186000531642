import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SalesUserService } from 'src/data/services/web/sales-user.service';
import { UserService } from 'src/data/services/web/user.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { environment } from 'src/environments/environment';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { MsfService } from 'src/data/services/web/msf.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-sales-activation-list',
  templateUrl: './sales-activation-list.component.html',
  styleUrls: ['./sales-activation-list.component.less']
})
export class SalesActivationListComponent implements OnInit {
  
  public localStorageCacheService: LocalStorageCacheService;
  public managerUserFormGroup: FormGroup;
  public salesUserService: SalesUserService;
  public managerUsersService: ManagerUsersService;
  public roleLevelId: any;
  public roleId: any;

  messageFormGroup : FormGroup;
  emptyString : any = '';
  migrationSalesList : any;
  migrationSalesListNeed : any;
  loader : any = true;
  migrationDetail: any;
  roleDetails: any;
  
  public activeTab = 1;

  constructor(private route: ActivatedRoute,
    private router: Router,
    localStorageCacheService: LocalStorageCacheService,
    private usersService: UserService,
    private cryptoService: CryptoService,
    private webSettingService: WebSettingsService,
    private msfService: MsfService,
    salesUserService: SalesUserService,
    managerUsersService: ManagerUsersService,
    private changeDetectorRef: ChangeDetectorRef,
    private fileUploadService : FileUploadService,
    private modalServe: NgbModal,
    private data: Data,
    private modService: NgbModal) { 
      this.localStorageCacheService = localStorageCacheService;
      this.salesUserService = salesUserService;
      this.managerUsersService = managerUsersService;
    }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    const username = this.roleLevelId == 1 ? null : this.localStorageCacheService.getStorage("username");

    this.initSalesUserActivationList(username);
    this.initMSFPromos();
  }

  initManagerUserFormGroup(migrationDetail: any): void {
    this.managerUserFormGroup = new FormGroup({
      username: new FormControl(migrationDetail.Username),
      email: new FormControl(migrationDetail.Email),
      firstName: new FormControl(migrationDetail.FirstName),
      lastName: new FormControl(migrationDetail.LastName),
      middleName: new FormControl(migrationDetail.MiddleName),
      address: new FormControl(migrationDetail.Address),
      contactNumber: new FormControl(migrationDetail.ContactNumber),
      roleId: new FormControl("Manager"),
      employeeId: new FormControl(this.emptyString),
      longitude: new FormControl(this.emptyString),
      latitude: new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      status: new FormControl(true),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),

      dtiFormId: new FormControl(this.emptyString),
      validID1Id: new FormControl(this.emptyString),
      validID2Id: new FormControl(this.emptyString),

      mainActivationDate: new FormControl(this.emptyString),
      enterpriseActivationDate: new FormControl(this.emptyString),
      sponsor: new FormControl(this.sponsorUsername.username),
      upline: new FormControl(this.uplineUsername.username),
      placement: new FormControl(migrationDetail.Placement),
      accountType: new FormControl(migrationDetail.RegistrationType),
      msfPromo: new FormControl(this.emptyString, Validators.required),
      storeType: new FormControl(this.emptyString, Validators.required),

      name: new FormControl(this.emptyString, Validators.required),
      maxCashiers: new FormControl(1),
      accountRemarks: new FormControl(this.emptyString),
      maxBillsAmountPerDay: new FormControl("100,000.00"),
      maxRemittanceAmountPerDay: new FormControl("100,000.00"),
      maxEpinsAmountPerDay: new FormControl("100,000.00"),
      maxEloadAmountPerDay: new FormControl("100,000.00"),

      number: new FormControl(this.emptyString),
      street: new FormControl(this.emptyString),
      subdivision: new FormControl(this.emptyString),
      barangay: new FormControl(this.emptyString, Validators.required),
      city: new FormControl(this.emptyString),
      municipality: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString),

      dtiRegistrationDate: new FormControl(this.emptyString, Validators.required),
      dtiRegistrationName: new FormControl(this.emptyString, Validators.required),
      atmWithdrawalMachine: new FormControl(this.emptyString, Validators.required),
      bankName: new FormControl(migrationDetail.BankName, Validators.required),
      bankAccountNumber: new FormControl(migrationDetail.BankAccountNumber, Validators.required),
      bankAccountName: new FormControl(migrationDetail.BankAccountName, Validators.required),

      comment: new FormArray([])
    });
  }

  initSalesUserActivationList(username: any) {
    this.usersService.getSalesUserActivationList(username).subscribe((result) => {
      this.migrationSalesList = result.data;
      this.loader = false;
    });
  }

  msfPromos: any;
  initMSFPromos(){
    this.msfService.getMSF().subscribe((result) => {
      this.msfPromos = result;
    });
  }
  
  modalMessageTransaction: NgbModalRef;
  modalRejectTransaction: NgbModalRef;
  mobileNo1;
  username;
  sponsorUsername;
  uplineUsername;
  async openActivationAdmin(messageModal, migration) {
    this.migrationDetail = JSON.parse(migration.meta);

    //console.log(this.migrationDetail);
    await this.getSponsorById(this.migrationDetail.SponsorId);
    await this.getUplineById(this.migrationDetail.UplineId);

    await this.initManagerUserFormGroup(this.migrationDetail);
    await this.initFilesFormGroup();

    if(this.migrationDetail.RoleId == 14){
      this.roleDetails = "Executive Director";
    } else if(this.migrationDetail.RoleId == 16){
      this.roleDetails = "Sales Director";
    } else if(this.migrationDetail.RoleId == 17){
      this.roleDetails = "Sales Manager";
    } else if(this.migrationDetail.RoleId == 18){
      this.roleDetails = "Sales Agent";
    } else if(this.migrationDetail.RoleId == 3){
      this.roleDetails = "Manager";
    }

    this.modalMessageTransaction = this.modService.open(messageModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  confirmReject(rejectModal){
    this.modalRejectTransaction = this.modService.open(rejectModal, {
      centered: true,
      size: "sm",
      backdrop: "static",
      keyboard: false,
    });
  }

  async getSponsorById(id: any){
    await this.salesUserService.getSalesUserById(id).subscribe((result) => { 
      this.sponsorUsername = result.data;
    });
  }

  async getUplineById(id: any){
    await this.salesUserService.getSalesUserById(id).subscribe((result) => { 
      this.uplineUsername = result.data;
    });
  }


  openActivationSales(id) {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    if(this.roleLevelId == 4){
      this.router.navigate(["sales", "activate", { id: encId }]);
    } else {
      this.router.navigate(["manager", "activate", { id: encId }]);
    }
  }

  createActivation(){
    if(this.roleLevelId == 4){
      this.router.navigate(["sales", "activate"]);
    } else {
      this.router.navigate(["manager", "activate"]);
    }
    
  }

  closeModal(){
    this.modalMessageTransaction.close();
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  setActiveTab(){
    this.activeTab++;
  }

  triggerFile(inputFile){
    inputFile.click();
  }

  public fileName: any = "Click here to choose...";

  public base64Photo: string | ArrayBuffer;
  public base64Photo1: string | ArrayBuffer;
  public base64Photo2: string | ArrayBuffer;
  public base64Photo3: string | ArrayBuffer;

  public fileFormGroup: FormGroup;

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      dtiForm: new FormControl(this.emptyString, Validators.required),
      validID1: new FormControl(this.emptyString, Validators.required),
      validID2: new FormControl(this.emptyString , Validators.required),
    });
  }

  submitted = false;
  get f() { return this.managerUserFormGroup.controls; }
  get fi() { return this.fileFormGroup.controls; }

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(reader.result);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          PNotify.info({
            title: "Activation Form",
            text: "You need to save the changes before the image reflects on view file.",
          });
        }else{
          PNotify.error({
                title: "Activation Form",
                text: "File is too big. Must be less than or equal to 2mb.",
              });
        }
      };
    }
  }

  public imagePreview: any;
  public uploadModal = null;
  fileType : any = "IMAGE";

  async preview(content: any,id : any){
    await this.fileUploadService.GetFileOutSingle(id).then(data =>{
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.uploadModal = this.modalServe.open(content, { centered: true, size:'xl', backdrop: 'static'});
    });

  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 2000000;
  }

  processActivation(){
    debugger
    if(this.roleId != "1" && this.roleId != "7"){
      PNotify.error({
        title: "Account Creation",
        text: "Finance Account is Required.",
      });
    } else{

      if(confirm(`Are you sure you want to migrate the account of ${this.managerUserFormGroup.controls.username.value} to JuanPay?`)) {

        var createAccountPayload =
        {
          username: this.managerUserFormGroup.controls.username.value,
          email: this.managerUserFormGroup.controls.email.value,
          firstName: this.managerUserFormGroup.controls.firstName.value,
          lastName: this.managerUserFormGroup.controls.lastName.value,
          address: this.managerUserFormGroup.controls.address.value,
          contactNumber: this.managerUserFormGroup.controls.contactNumber.value,
          roleId: "Manager",
          employeeId: this.emptyString,
          photo: this.emptyString,
          status: true,
          redirect: environment.EMAIL_VERIFICATION_REDIRECT,
          
          mainActivationDate: Date.now,
          enterpriseActivationDate:  Date.now,
          sponsor: this.managerUserFormGroup.controls.sponsor.value,
          upline: this.managerUserFormGroup.controls.upline.value,
          placement: this.managerUserFormGroup.controls.placement.value,
          accountType: this.managerUserFormGroup.controls.accountType.value,
          mSFPromo: this.managerUserFormGroup.controls.msfPromo.value,
          storeType: this.managerUserFormGroup.controls.storeType.value,
  
          name: this.managerUserFormGroup.controls.name.value,
          maxCashiers: this.managerUserFormGroup.controls.maxCashiers.value,
          accountRemarks: this.managerUserFormGroup.controls.accountRemarks.value,
          maxBillsAmountPerDay: this.managerUserFormGroup.controls.maxBillsAmountPerDay.value,
          maxRemittanceAmountPerDay: this.managerUserFormGroup.controls.maxRemittanceAmountPerDay.value,
          maxEpinsAmountPerDay: this.managerUserFormGroup.controls.maxEpinsAmountPerDay.value,
          maxEloadAmountPerDay: this.managerUserFormGroup.controls.maxEloadAmountPerDay.value,
  
          number: this.managerUserFormGroup.controls.number.value,
          street: this.managerUserFormGroup.controls.street.value,
          subdivision: this.managerUserFormGroup.controls.subdivision.value,
          barangay: this.managerUserFormGroup.controls.barangay.value,
          city: this.managerUserFormGroup.controls.city.value,
          municipality: this.managerUserFormGroup.controls.municipality.value,
          region: this.managerUserFormGroup.controls.region.value,
          longtitude: this.managerUserFormGroup.controls.longitude.value,
          latitude: this.managerUserFormGroup.controls.latitude.value
        };
        
        //console.log("createAccountPayload : ", createAccountPayload);
        this.actionEventCreateManagerUser(createAccountPayload);
  
      }


    }
  }
  
  processAgentActivation(){
    debugger
    if(this.roleId != "1"){
      PNotify.error({
        title: "Account Creation",
        text: "Finance Account is Required.",
      });
    } else{

      if(confirm(`Are you sure you want to create the account of ${this.managerUserFormGroup.controls.username.value} to JuanPay?`)) {

        var createAccountPayload =
        {
          username: this.migrationDetail.Username,
          email: this.migrationDetail.Email,
          contactNumber: this.migrationDetail.ContactNumber,
          redirect: environment.EMAIL_VERIFICATION_REDIRECT
        };
        
        this.actionEventCreateSalesAgentUser(createAccountPayload);
  
      }


    }
  }

  processCreate = false;
  public loaderMessage : any;

  async actionEventCreateManagerUser(createAccountPayload : any): Promise<void> {
    this.processCreate = true;

    var fileCount = this.fileFormGroup.controls.length;
    var fileCounter = 1;
    for (const field in this.fileFormGroup.controls) { 
      debugger
      if(this.fileFormGroup.controls[field].value){
        var file = {
          fileName : field,
          managerUserId : 0,
          photoFile : this.fileFormGroup.controls[field].value,
          purpose : "File Manager",
          username : this.username
        }
        await this.fileUploadService.FileUploadOutSingle(file).then(data =>{ 
          this.managerUserFormGroup.controls[field + 'Id'].setValue(data);
          this.loaderMessage = `Uploading ${fileCounter} of ${fileCount}. Please wait.`;
            fileCounter++;
        });
      }
    }

    this.managerUsersService.createSalesUserMigration(createAccountPayload).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        
        PNotify.success({
          title: "Success",
          text: "The account has been successfully created.",
        });
        this.processCreate = false;
        this.modalMessageTransaction.close();

        this.router.navigate(["acm", "manager-user-view", { id: encId, status: encNewData }]);
      },
      (error) => {

        if (error.status === 400) {

          this.processCreate = false;
          this.loaderMessage = "";
          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors. " + JSON.stringify(error.error.errors)  ,
          });
        } else {
          // Other error
          this.processCreate = false;
          this.loaderMessage = "";

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  async actionEventCreateSalesAgentUser(createAccountPayload : any): Promise<void> {
    this.processCreate = true;

    this.managerUsersService.createSalesAgentUserMigration(createAccountPayload).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        
        PNotify.success({
          title: "Success",
          text: "The account has been successfully created.",
        });
        this.processCreate = false;
        this.modalMessageTransaction.close();

        this.router.navigate(["acm", "sales-user-view", { id: encId, status: encNewData }]);
      },
      (error) => {

        if (error.status === 400) {

          this.processCreate = false;
          this.loaderMessage = "";
          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors. " + JSON.stringify(error.error.errors)  ,
          });
        } else {
          // Other error
          this.processCreate = false;
          this.loaderMessage = "";

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  rejectActivation(){
    
  }

  paymentModalRef: NgbModalRef;
  openPaymentModal(content){
    this.paymentModalRef = this.modalServe.open(content, { centered: true, size:'xl', backdrop: 'static'});
  }

}
